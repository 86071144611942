<template>
  <v-card flat v-if="!loading_initial">
    <v-container class="pa-6" fluid v-if="!mother || (mother && !mother.drawn && !mother.drawnBag)">
      <div class="text-center">
        <h2>Ops! mãe não sorteada</h2>
        <v-btn dark color="green" class="mt-3" href="https://bit.ly/3AB09Q0" target="_blank"><v-icon left>mdi-whatsapp</v-icon>Falar Conosco</v-btn>
      </div>
    </v-container>
    <v-container class="pa-6" fluid v-else>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" v-if="!mother">
            <v-text-field
              v-model="token"
              :rules="tokenRules"
              label="Senha"
              outlined
              placeholder=""
              required
              rounded
              mask="####"
              append-icon="mdi-account-search-outline"
              clearable
              @click:append="getMotherByToken(token)"
              @keyup.enter="getMotherByToken(token)"
              :disabled="!!mother"
            />
          </v-col>
          <v-col cols="12" sm="12" v-if="mother">
            Mãe: <h2>{{ mother.name }}</h2>
          </v-col>
          <!-- <v-col cols="12" sm="10" v-if="mother">
            <v-container align="start">
              <v-row
                align="center"
              >
                <span v-if="Array.isArray(mother.drawnBag) && mother.drawnBag.includes(this.defaultYearParty)" class="pl-3 font-weight-bold">Onde fica mais perto para você pegar as sacolas?</span>
                <span v-else class="pl-3 pr-3 font-weight-bold">Você mora próximo a</span>
                <v-autocomplete
                  v-model="pickUpPlace"
                  :items="mother.drawn ? placeItems : pickUpPlaceItems"
                  item-value="value"
                  item-text="label"
                  outlined
                  required
                  rounded
                  :label="!pickUpPlace ? 'Selecione aqui...' : ''"
                  color="indigo"
                  @change="savePickUpPlace(mother.id, pickUpPlace)"
                  :rules="[ value => !!value || 'Informe o local mas próximo para você pegar a sacolas.' ]"
                >
                </v-autocomplete>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="loading_mother"
                  class="mb-6 pr-3"
                ></v-progress-circular>
                <v-icon class="mb-6 pl-2" color="green" v-if="mother.pickUpPlace && !loading_mother">mdi-check-outline</v-icon>
              </v-row>
            </v-container>
          </v-col> -->
          <v-col cols="12" sm="12" v-if="mother && mother.pickUpPlace">
            <v-btn 
              class="mb-3" 
              :color="children && children[index] ? 'blue darken-3' : 'orange darken-3'" 
              dark 
              rounded 
              block 
              v-for="(item,index) in mother.sons"
              :key="index"
              @click="addChildren(mother.id, index, children[index])"
            >
              <span v-if="children && children.length > index">
                <v-icon left>{{children[index].gender === 'F' ? 'mdi-face-woman-outline' : 'mdi-account-cowboy-hat'}}</v-icon>
                EDITAR {{children[index].name}}
              </span>
              <span v-else>Cadastrar {{index + 1}}º Filho</span>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" v-if="mother && children && children.length === mother.sons && mother.pickUpPlace">
            <span class="font-weight-bold text--center">
              <v-icon color="green">mdi-check-outline</v-icon>
              Muito bem! Vc concluiu o cadastro de todos os seus filhos. Qualquer dúvida vamos entrar em contato com você pelos WhatsApp.<br><br>
              <p v-if="mother.drawnBag">Até dia 25 de novembro vamos enviar a data e o endereço para você retirar as sacolas. ❤️🎁</p>
            </span>
          </v-col>
          <!-- <v-col cols="12" sm="12" v-if="mother">
            <v-btn color="indigo darken-3" dark rounded block @click.prevent="clear">
              Acessar outra mãe
            </v-btn>
          </v-col> -->
        </v-row>
      </v-form>
    </v-container>
    <subscribe-children-form/>
  </v-card>
</template>
<script>
  import { bus } from '../../main';
  import { mapActions, mapState } from 'vuex'
import SubscribeChildrenForm from './SubscribeChildrenForm.vue';
  export default {
    components: { SubscribeChildrenForm },
    name: 'SubscribeForm',
    data: () => ({
      valid: false,
      token: '',
      tokenRules: [
        (v) => !!v || 'Ops! Informe a sua senha de cadastro.',
        (v) => (v && v.length >= 4) || 'Ops! Informe sua senha de cadastro.',
      ],
      pickUpPlace: '',
      pickUpPlaceItems: [
        {
          label: 'Próximo a Penha',
          value: 'Penha'
        },
        {
          label: 'Próximo ao Ibirapuera',
          value: 'Ibirapuera'
        }
      ],
      placeItems: [
        {
          label: 'PENHA',
          value: 'Penha'
        },
        {
          label: 'IBIRAPUERA',
          value: 'Ibirapuera'
        },
        {
          label: 'CEASA',
          value: 'Ceasa'
        },
        {
          label: 'SANTANA',
          value: 'Santana'
        },
      ],
      error: null,
      loading: false,
      loading_mother: false,
      loading_initial: true
    }),
    mounted() {
      let id = this.$route.params.id
      if(id) {
        this.getMother(id)
          .then(() => {
            let params = {
              mother_id: id,
              year: this.selectedYear
            }
            this.getChildrenByParams(params)
              .then(()=> this.loading_initial = false)
              .catch(err => {
                console.error(err)
                this.loading_initial = false
              })
          })
          .catch(err => {
            console.error(err)
            this.loading_initial = false
          })
      }
    },
    computed: {
      ...mapState(['config']),
      ...mapState('children', ['mothers', 'mother', 'children']),
      ...mapState(['defaultYearParty', 'selectedYear'])
    },
    watch: {
      mother(val) {
        this.token = val.token
        this.pickUpPlace = val.pickUpPlace
      }
    },
    methods: {
      ...mapActions('children', ['getMothers', 'clearState', 'getMother', 'updateMother', 'getChildrenByParams']),
      savePickUpPlace(id, pickUpPlace) {
        this.loading_mother = true
        let data = { id: id, 'pickUpPlace': pickUpPlace }
        this.updateMother(data)
          .then(() => {
            this.loading_mother = false
          })
          .catch(err => {
            console.error(err)
            this.error = 'Erro ao realizar o cadastro'
            this.loading_mother = false
          })
      },
      getMotherByToken(token) {
        this.getMothers({token: token})
          .then(mothers => {
            if(mothers.length > 0)
              this.$store.children.commit('setMother', mothers[0])
            else 
              this.error = 'Mãe não encontrada'
          })
          .catch(err => console.error(err))
      },
      addChildren(mother_id, index, child) {
        if(!this.pickUpPlace) {
          this.error = 'Primeiro selecione o local mas próximo para você pegar a sacolas'
          return
        }
        let params = {
          mother_id: mother_id,
          index: index,
          dialog: true
        }
        if(child) params.child = child
        bus.$emit('openChildrenDialog', params)
      }
    },
  }
</script>
